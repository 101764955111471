'use strict';

var selectedImageIndex;

$(window).on('load', function () {
    initThumbSlider();
    initMainSlider();
});

$(document).ready(function() {
    var zoomContainer = $('.js-section-zoom-img');
    var minusButton = $('.js-btn-minus-zoom');
    var plusButton = $('.js-btn-plus-zoom');
    var zoomText = $('.js-current-zoom');

    var currentZoom = 0;
    var xOffset = 0;
    var yOffset = 0;

    minusButton.on('click', function() {
        currentZoom -= 10;
        if (currentZoom < 0) {
            currentZoom = 0;
        }
        updateZoom();
    });

    plusButton.on('click', function() {
        currentZoom += 10;
        updateZoom();
    });

    function updateZoom() {
        zoomText.data('value-zoom', currentZoom);
        minusButton.prop('disabled', currentZoom < 10);
        plusButton.prop('disabled', currentZoom >= 100);

        zoomText.text(currentZoom + '%');
    }

    zoomContainer.on('mousemove', function(e) {
        if (currentZoom > 0) {
            var slideImgCurrent = $(this).find('.swiper-slide-active img');
            var imgWidth = slideImgCurrent.width();
            var imgHeight = slideImgCurrent.height();

            var zoomFactor = (100 + currentZoom) / 100; // Calcolo del fattore di zoom

            var xOffsetMax = (imgWidth * zoomFactor - imgWidth) / 2;
            var yOffsetMax = (imgHeight * zoomFactor - imgHeight) / 2;

            var xOffsetPercent = (e.pageX - $(this).offset().left) / $(this).width();
            var yOffsetPercent = (e.pageY - $(this).offset().top) / $(this).height();

            xOffset = (xOffsetPercent * 2 - 1) * xOffsetMax * -1;
            yOffset = (yOffsetPercent * 2 - 1) * yOffsetMax * -1;

            slideImgCurrent.css({
                transform: 'scale(' + zoomFactor + ')',
                transformOrigin: xOffsetPercent * 100 + '% ' + yOffsetPercent * 100 + '%'
            });
        }
    });

    zoomContainer.on('mouseleave', function() {
        xOffset = 0;
        yOffset = 0;
        var slideImgCurrent = $(this).find('.swiper-slide-active img');
        slideImgCurrent.css({
            transform: 'none',
            transformOrigin: 'center center'
        });
    });
});

let mainSlider = null;

function initMainSlider() {
    if (!mainSlider) {
        mainSlider = new Swiper('.js-main-carousel-zoom', {
            modules: [Thumbs, Pagination, Navigation],
            spaceBetween: 16,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper: thumbSlider,
            }
        });

    } else {
        mainSlider.update();
    }

    $('.js-image-swiper').on('click', function () {
        selectedImageIndex = $(this).parent('.swiper-slide-active').data('slide-id');
    });

    $('.js-modal-zoom-pdp').on('show.bs.modal', function () {
        $('.js-main-carousel-zoom .swiper-slide').each(function () {
            mainSlider.slideTo(selectedImageIndex);
        });
    });
}
let thumbSlider = null;

function initThumbSlider() {
    if ($('.js-thumbnail-carousel').find('.swiper-slide').length > 1) {
        if (!thumbSlider) {
            thumbSlider = new Swiper('.js-thumbnail-carousel', {
                modules: [Pagination, Navigation],
                spaceBetween: 30,
                freeMode: true,
                watchSlidesProgress: true,
                swipe: false,
                slidesPerView: 2,
                direction: 'horizontal',
                touchRatio: 0,
                breakpoints: {
                    576: {
                        direction: "horizontal",
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                    768: {
                        direction: "vertical",
                        slidesPerView: 5,
                        spaceBetween: 16,
                    }
                },
            });
        } else {
            thumbSlider.update();
        }
    }
}

$(document).on('myCustomEvent', function (event, data) {
    updateThumbSlider(data.product);
    updateMainSlider(data.product);
});


function updateMainSlider(product) {
    createSwiperMain(product.images.zoom);
}

function updateThumbSlider(product) {
    createSliderThumbnail(product.images.zoom);
}

function createSliderThumbnail(images) {
    $('.js-wrapper-thumbnail').find('.swiper-slide-thumbnail').remove();

    var $newSlide;
    images.forEach(image => {
        $newSlide = $('<div class="swiper-slide js-swiper-slide-product-detail-thumbnail swiper-slide-thumbnail">' +
            '<div class="swiper-slide-thumbnail-zoom"><img class="img-swiper-thumbnail" src="' + image.url + '" alt="' + image.alt + '"></div></div>');
        $('.js-wrapper-thumbnail').append($newSlide);
    });

    initThumbSlider();
}

function createSwiperMain(images) {
    $('.js-wrapper-main-zoom').find('.js-swiper-slide-product-detail-zoom').remove();

    var $newSlide;
    images.forEach(image => {
        $newSlide = $('<div class="swiper-slide js-swiper-slide-product-detail-zoom" data-index="' + image.index + '">' +
            '<img class="img-swiper-zoom-slide lazyload" data-src="' + image.url + '" src="' + image.url + '" alt="' + image.alt + '"/>' +
            '</div>');
        $('.js-wrapper-main-zoom').append($newSlide);
    });

    initMainSlider();
}

$('.js-image-swiper:not(.js-image-swiper-bundle), .js-iframe-overlay:not(.js-image-swiper-bundle)').on('click', function (e) {
    e.preventDefault();
    var imageClick = $(this);
    if(imageClick.closest('.product-bundle').length === 0) {
        var swiperWrapper = imageClick.closest('.js-wrapper-product-detail');
        var slidesZoom = swiperWrapper.data('slides-zoom');

        createSliderThumbnail(slidesZoom);
        createSwiperMain(slidesZoom);
    }
});

$('.js-modal-zoom-pdp').on('shown.bs.modal', function () {
    $('body').css('overflow', 'hidden');
});
