'use strict';

const cache = {
    $corporateLink: $('.corporate-link'),
    $corporateCard: $('.corporate-card'),
    $corporateBackdrop: $('.corporate-backdrop'),
    $closeBtn: $('.corporate-card .border-btn')
};

$(document).ready(function () {
    if (cache.$corporateLink) {
        cache.$corporateLink.on('click', function () {
            if (cache.$corporateCard.hasClass('d-none')) {
                cache.$corporateCard.removeClass('d-none');
            } else {
                cache.$corporateCard.addClass('d-none');
            }
        });

        cache.$corporateLink.on('mouseout', function () {
            if (!window.isMobile()) {
                cache.$corporateCard.addClass('d-none');
            }
        });

        cache.$corporateLink.on('mouseover', function () {
            if (!window.isMobile()) {
                cache.$corporateCard.removeClass('d-none');
            }
        });

        cache.$corporateBackdrop.on('click', function () {
            cache.$corporateCard.addClass('d-none');
        });

        cache.$closeBtn.on('click', function () {
            cache.$corporateCard.addClass('d-none');
        });
    }

    $('#footerAccordion .collapse').on('show.bs.collapse', function () {
        if ($(this).closest('footer').length) {
            let $cardBody = $(this).find('.card-body');
            if ($cardBody && $cardBody[0] && $cardBody[0].innerHTML.trim() === '') {
                let classes = $cardBody.attr('class').split(/\s+/);

                let targetClass = classes.find(function (cls) {
                    return cls.startsWith('footer-');
                });

                if (targetClass) {
                    let $sourceContent = $('footer .' + targetClass).not($cardBody).first();

                    if ($sourceContent.length) {
                        $cardBody.html($sourceContent.html());
                    }
                }
            }
        }
    });
});
