'use strict';

var base = require('base/search/search');
const eventHandlers = require('../tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;

const cache = {
    $sortRadio: $('.js-sort-radio'),
    $orderSelect: $('.js-order-select'),
    $productGrid: $('.product-grid')
};

/**
 * Update the browser's URL and push a new state to the history.
 *
 * @param {string} state - The name of the event state (e.g., 'filter').
 * @param {string} urlNoAjax - The URL to be displayed in the address bar.
 * @param {string} url - The URL to be saved in the history state for future AJAX requests.
 * @return {undefined}
 */
function updateUrl(state, urlNoAjax, url) {
    history.pushState(
        { page: state, ajax: url },
        '',
        urlNoAjax
    );
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} context - Base context for correct injection
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, context, selector) {
    var $updates = $results.find(selector);
    $(context).find(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} context - Base RefinementsBar context selector
 * @return {undefined}
 */
function handleRefinements($results, context) {
    let $refinementCards = $(context).find('.refinements .card');
    var states = $refinementCards.map(function() {
        var expanded = $(this).find('.btn').attr('aria-expanded') === 'true';
        var isEmpty = $(this).find('.card-body').is(':empty');
        var cardId = $(this).find('.card-header').attr('id');
        return {
            id: cardId,
            expanded: expanded,
            isEmpty: isEmpty
        };
    }).get();
    updateDom($results, context, '.refinements');

    // Restores the stored aria-expanded and hides the empty cards
    states.forEach(function(s) {
        if (!s) return;

        let $card = $(context).find('.refinements .card-header#' + s.id).closest('.card');
        if ($card.length === 0) {
            return;
        }

        if (s.isEmpty) {
            $card.hide();
        }

        if (s.expanded !== 'undefined') {
            let btn = $card.find('.btn');
            btn.attr('aria-expanded', s.expanded);
            if (s.expanded) {
                $card.find('.collapse').addClass('show');
                let accordionContainer = $card.closest('.custom-border');
                accordionContainer.addClass('accordion--open');
                const icon = accordionContainer.find('.icon');
                icon.removeClass('icon-plus').addClass('icon-minus');
            }
        }

    });
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @param {string} context - Base selector
 * @return {void}
 */
function parseResults(response, context) {
    var $results = $(response);

    // Update DOM elements that do not require special handling
    [
        '.result-count',
        '.header-bar',
        '.js-update-order',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar'
    ].forEach(function (selector) {
        updateDom($results, context, selector);
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @param {Object} $context
 * @return {undefined}
 */
function updateSortOptions(response, $context) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options');

    if (sortOptions) {
        sortOptions.options.forEach(function (option) {
            $context.closest('option.' + option.id).val(option.url);
        });
    }
}

function scrollToElement(element) {
    if(!element || $(element).length === 0) return;

    var elHeight = element.height();
    var windowHeight = $(window).height();

    if (elHeight < windowHeight) {
        $(window).on('load', function() {
            $('html, body').animate({scrollTop: element.offset().top - ((windowHeight / 2) - (elHeight / 2))}, 'fast');
        });
    } else {
        $(window).on('load', function() {
            $('html, body').animate({scrollTop: element.offset().top}, 'fast');
        });
    }
}

/**
 * Updates the display count of selected filters and controls the reset button state.
 * Enables the reset button if filters are selected; disables it otherwise.
 *
 * @return {undefined}
 */
function updateFiltersCount(context) {
    let $context = $(context);
    var count = $context.find('.refinements .btn-custom-filter.selected, .refinements .js-btn-color-filter.selected');
    var numFilters = count.length;
    var resetButton = $context.find('.box-content-btn .btn.reset');

    let $filterBadge = context !== '#refinementsSidebar'
        ? $context.find('.js-filter .badge')
        : $('.js-filter .badge');

    if (numFilters > 0) {
        $filterBadge.addClass('visible');
        $filterBadge.html('(' + numFilters + ')');
        resetButton.removeClass('disabled');
        resetButton.prop('disabled', false);
    } else {
        resetButton.addClass('disabled');
        resetButton.prop('disabled', true);
        $filterBadge.removeClass('visible');
        $filterBadge.html('');
    }
}

/**
 * Applies filters by making an AJAX request to the given URL.
 * Updates the results, filters count, and manages a loading spinner.
 *
 * @param {string} url - The URL to request filtered data from
 * @param {string} gridContext - Base context for search Results grid
 * @param {string} refinementsBarContext - Base context for refinements bar
 */
function applyFilterAjax(url, gridContext, refinementsBarContext) {
    $.spinner().start();

    gridContext = gridContext || 'body';
    refinementsBarContext = refinementsBarContext || 'body';

    let $context = $(gridContext);

    $.ajax({
        url: url,
        data: {
            page: $context.find('.grid-footer').data('page-number')
        },
        method: 'GET',
        success: function (response) {
            parseResults(response, gridContext);

            var specialHandlers = {
                '.refinements': {
                    fn: handleRefinements,
                    context: refinementsBarContext
                }
            };
            let $results = $(response);
            Object.keys(specialHandlers).forEach(function (selector) {
                let handler = specialHandlers[selector];
                handler.fn($results, handler.context);
            });

            updateFiltersCount(refinementsBarContext);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    }).done(() => {
        window.scrollTo(0, 0);
    });
}

function modifyUrlParams(url, params) {
    if (!url || !params) return url;

    let [baseUrl, queryStr] = url.split('?');
    const searchParams = new URLSearchParams(queryStr);

    for (let key in params) {
        if(params.hasOwnProperty(key)){
            searchParams.set(key, params[key]);
        }
    }

    return `${baseUrl}?${searchParams.toString()}`;
}

function updateButtonUrls($button, newHref, newUrlNoAjax) {
    $button.data('href', newHref);
    $button.data('urlnoajax', newUrlNoAjax);
}

base.sort = function () {
    $('.dropdown-menu, .js-order-content').on('click', '.js-sort-order', function (e) {
        e.preventDefault();

        const $this = $(this);
        cache.$sortRadio.removeClass('checked');
        $this.find('div:first').addClass('checked');

        const url = $this.data('href');
        const urlnoajax = $this.data('urlnoajax');

        updateUrl('filter', urlnoajax, url);

        const displayName = $this.data('name');
        cache.$orderSelect.empty().text(`: ${displayName}`);

        $.spinner().start();

        const urlParams = new URLSearchParams(new URL(url).search);
        const urlNoAjaxParams = new URLSearchParams(new URL(urlnoajax).search);

        $.ajax({
            url: url,
            data: { selectedUrl: url },
            method: 'GET',
            success: function (response) {
                cache.$productGrid.empty().html(response);
                $.spinner().stop();

                $('ul.values.content').find('button[data-href], button[data-urlnoajax]').each(function () {
                    const $button = $(this);
                    const newHref = modifyUrlParams($button.data('href'), Object.fromEntries(urlParams));
                    const newUrlNoAjax = modifyUrlParams($button.data('urlnoajax'), Object.fromEntries(urlNoAjaxParams));
                    updateButtonUrls($button, newHref, newUrlNoAjax);
                });
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('click', '.js-sort-order', function (e) {
        const $this = $(this);
        const url = $this.data('href');
        const urlSearchParams = new URLSearchParams(new URL(url).search);
        const srule = urlSearchParams.get('srule');

        $this.parent().find('input[name="sortOrder"]').prop('checked', false); // Deselect all inputs
        $this.find('input[name="sortOrder"]').prop('checked', true); // Select current input

        $this.parent().find('.js-sort-radio').removeClass('checked');
        $this.find('.js-sort-radio').addClass('checked');

        let $spinner = $('.js-suggestions-wrapper').spinner();
        $spinner.start();

        $.ajax({
            url: url,
            data: { selectedUrl: url },
            method: 'GET',
            success: function (response) {
                $('.js-suggestions-wrapper .product-grid').empty().html(response);
                $spinner.stop();

                $('#searchPhraseRefinementsSidebar').find('button[data-href]:not(.js-sort-order,.reset-filter)').each(function () {
                    let $button = $(this);
                    let btnURL = new URL($button.data('href'), window.location.href);
                    btnURL.searchParams.set('srule', srule);
                    $button.data('href', btnURL.toString());
                });
            },
            error: function () {
                $spinner.stop();
            }
        });
    });
};

base.showMore = function () {
    // Show more products
    $('body').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        var showMoreUrl = $(this).data('url');
        let $this = $(this);
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:showMore', e);
        $.ajax({
            url: showMoreUrl,
            method: 'GET',
            success: function (response) {
                $this.closest('.grid-footer').replaceWith(response);
                var infoItems = $(response).find('.js-info-tracking');
                if(infoItems.length > 0) {
                    eventHandlers.sendEvent(infoItems, eventsType.viewItemList);
                }
                updateSortOptions(response, $this);

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

base.applyFilter = function () {
    // Handle refinement value selection and reset click
    $('#refinementsSidebar').on(
        'click',
        '.refinements li button,  .filter-value button, .swatch-filter button',
        function (e) {
            e.preventDefault();
            e.stopPropagation();
            const url = $(this).data('href');
            const urlnoajax = $(this).data('urlnoajax');
            updateUrl('filter', urlnoajax,url);
            $(this).trigger('search:filter', e);
            applyFilterAjax(url, '.search-results', '#refinementsSidebar');
        });

    $('body').on('click', '#searchPhraseRefinementsSidebar .refinements li button, ' +
        '#searchPhraseRefinementsSidebar .filter-value button, ' +
        '#searchPhraseRefinementsSidebar .swatch-filter button',
        function (e) {
            e.preventDefault();
            e.stopPropagation();
            const url = $(this).data('href');
            $(this).trigger('searchPhrase:filter', e);
            applyFilterAjax(url, '.js-suggestions-wrapper', '#searchPhraseRefinementsSidebar');
        });
};

base.resetRefinement = function () {
    $('#refinementsSidebar').on(
        'click',
        ' button.reset',
        function (e) {
            e.preventDefault();
            e.stopPropagation();
            $.spinner().start();

            var currentUrl = new URL(window.location.href);
            var cgid = currentUrl.searchParams.get('cgid');
            var categoryUrl = currentUrl.origin + currentUrl.pathname;

            var categoryUrlWithCgid = new URL(categoryUrl);
            if (cgid) {
                categoryUrlWithCgid.searchParams.set('cgid', cgid);
            }
            categoryUrlWithCgid = categoryUrlWithCgid.toString();

            window.history.replaceState({}, document.title, categoryUrlWithCgid);
            $('.refinements .btn-custom-filter.selected, .refinements .js-btn-color-filter.selected').removeClass('selected');
            updateUrl('filter', categoryUrlWithCgid, categoryUrlWithCgid);
            applyFilterAjax(categoryUrlWithCgid, '.search-results', '#refinementsSidebar');
            $.spinner().stop();
        });

    $('body').on('click', '#searchPhraseRefinementsSidebar button.reset', function (e) {
            e.preventDefault();
            e.stopPropagation();

            $.spinner().start();
            const url = $(this).data('href');
            applyFilterAjax(url, '.js-suggestions-wrapper', '#searchPhraseRefinementsSidebar');
        });
};


base.setup = function () {
    var navigationData = localStorage.getItem('navigation');
    var elementToScroll = null;

    if (navigationData) {
        navigationData = JSON.parse(navigationData);
        if (navigationData.pid) {
            elementToScroll = $('.product[data-pid="' + navigationData.pid + '"');
        } else if (navigationData.href) {
            elementToScroll = $('.pdp-link[href="' + navigationData.href + '"');
        }
        localStorage.removeItem('navigation');
    }

    if (!elementToScroll) {
        $(window).scrollTop(0);
    } else {
        scrollToElement(elementToScroll);
    }

    var action = $('.page').data('action');
    if (action === 'Search-Show') {
        updateFiltersCount('#refinementsSidebar');
        let currentUrl = window.location.href;
        history.pushState({page: 'filter'}, '', currentUrl);

        /**
         * Handles popstate event to apply filters if page is 'filter'.
         */
        addEventListener("popstate", (event) => {
            const state = event.state;

            if (state && state.page === 'filter') {
                applyFilterAjax(state.ajax, 'body', 'body');
            }
        });
    }
};

$(document).ready(function () {
    var infoItems = cache.$productGrid.find('.js-info-tracking');
    if(infoItems.length > 0) {
        eventHandlers.sendEvent(infoItems, eventsType.viewItemList);
    }
});

module.exports = base;
